import React, {Component} from 'react';

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import "babel-polyfill";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import ScrollToTop from "./utils/ScrollToTop";
import Homepage from "./components/pages/Homepage";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <ScrollToTop>
          <Switch>
            <Route path={"/"} component={Homepage} exact/>
            <Route path={"/update"} component={Homepage} exact/>
            <Route component={Homepage} exact/>
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

export default App;
