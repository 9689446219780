import React, {Component} from 'react';
import styled from 'styled-components';
import Vimeo from "@u-wave/react-vimeo";
const TextWrapper = styled.div`
  width: 90%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  text-align: center;
  font-family: Garamond, sans-serif;
  line-height: 2em;
  padding-bottom: 35px;
  & > i {
    font-size: 20pt;
  }
`;
const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
`;
const VimeoWrapper = styled.div`
  width: 90%;
  padding-top: 5%;
  min-height: 80vh;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  z-index: 1000;
  border-radius: 4px;
  //background-color: white;
  //box-shadow: 0 0 10px 2px dimgray;
  cursor: auto;
  & > svg {
    color: #232323;
    position: absolute;
    top: 20px;
    right: 20px;
    &:hover {
      cursor: pointer
    }
  }
  @media screen and (max-width: 600px) {
    height: auto;
    padding: 30px 0 30px 0;
    min-height: 45vh;
    & > svg {
      top: 10px;
      right: 7px;
    }
  }
`;

export default class Homepage extends Component {
  state: {
    showVideo: boolean,
    screenWidth: number,
  } = {
    showVideo: true,
    screenWidth: window.innerWidth,
  }
  componentWillUnmount() {
    window.removeEventListener("resize", () => this.resized());
  }
  resized() {
    this.setState({screenWidth: window.innerWidth})
  }
  componentDidMount() {
    window.addEventListener("resize", () => this.resized());
    // setTimeout(() => {
    //   this.setState({showVideo: true});
    // }, 1000);
  }

  getPlayerWidth() {
    let w = this.state.screenWidth;
    if (w < 600)
      return w * 0.95;
    else if (w >= 600 && w < 1400)
      return w * 0.8;
    else if (w >= 1400 && w < 1920)
      return w * 0.7;
    else if (w >= 1920)
      return w * 0.65;
  }
  render() {
    return (
      <Wrapper>
        <VimeoWrapper>
          {/*<FaTimes onClick={() => this.handleCloseClick()}/>*/}
          <Vimeo
            video={646494307}
            autopause
            autoplay
            paused={this.state.showVideo === false}
            width={this.getPlayerWidth()}
          />
        </VimeoWrapper>
        <TextWrapper>
          <i>Transcript of the Video</i><br/>
          It feels good to be back with<br/>
          a progress report<br/>
          I’ll take this time to reflect on the principle of progress<br/>
          And the reasons behind the endeavor I’m on<br/>
          Okay! Let’s start with progress.<br/>
          I believe that to be governed by the two opposing principles<br/>
          the precautionary and the proactionary principle .<br/>
          The first takes caution about the learnings and the uncertainty.<br/>
          The second seeks the fruit in face of the potential risk and is driven by<br/>
          a fundamental optimism.<br/>
          Which bring us to the question:<br/>
          Why are things the way they are?<br/>
          It’s a tough question to answer<br/>
          But I find that there are three ways one can reason their way through<br/>
          One is fractal like reasoning,<br/>
          A way of correctly self referencing.<br/>
          The second is cyclical reasoning,<br/>
          I refer to you because you refer to me<br/>
          The third is,<br/>
          Pointing towards an example<br/>
          And once that is understood,<br/>
          I find that the proactionary force is a great example to uphold for progress.<br/>
          To seek the fruit in face of the potential risk and be driven by<br/>
          a fundamental optimism.<br/>
          The question then becomes:<br/>
          Can you be an optimist while<br/>
          hearing bad news about your health?<br/>
          One time I visited an endocrinologist in<br/>
          Toronto who said it’s time for me to hear<br/>
          the honest truth:<br/>
          You are a young healthy man who doesn’t<br/>
          take good care of their body.<br/>
          This response stuck with me for while and<br/>
          I was thinking:<br/>
          This doctor will judge my behavior based<br/>
          on one metric alone, my blood glucose.<br/>
          They will not know<br/>
          What I am doing and what decisions could be made better in my life.<br/>
          I thought there is ought to be a better way where patients are not judged based on one single metric alone<br/>
          Where the cause and effect relationship is part of the system of tools that a doctor can rely on<br/>
          I wanted to develop a tool that is a testament to the proactionary force.<br/>
          That’s why I wanted set an example to answer the question:<br/>
          Is it possible?<br/>
          Can I set a new standard for medical best practice?<br/>
          So I set out to develop a way to pair caloric output with changes in blood glucose and<br/>
          have the data be seen and analyzed this way.<br/>
          And so the goal is set for me :<br/>
          Create the system and use it.<br/>
          And on top of that I want to challenge myself<br/>
          And complete the Half Ironman by 2023<br/>
          and help other diabetics be able to do<br/>
          even more<br/>
          And along the way perhaps inspire a little one<br/>
          Because I find it motivating that one day I may become a dad<br/>
          And if my children will inherit my condition I would want them to have better ways to deal with it<br/>
          And what’s personal is universal<br/>
          Turns out there are fellow future parents in the same shoes.<br/>
          So a solution is due,<br/>
          But the first test came before I even had enough time to get started<br/>
          Someone believed it was time for me to embrace chaos<br/>
          And I was strikingly an easy target<br/>
          One of my friends told me it was bound to happen:<br/>
          She laughed and said not everyone is writing about the contrast between<br/>
          what they heard at Fauci’s lecture at the Academy and the Great Barrington Declaration<br/>
          You know… These sort of courageous young masterminds find their villains fast.<br/>
          Of course you have been hacked.<br/>
          Then she continued in a soft voice:<br/>
          It was part of the path.<br/>
          And if you think this inquiry in biophysics has gone unnoticed then<br/>
          let me be the friend to reveal to you :<br/>
          Perhaps you are interesting to<br/>
          more people than you think<br/>
          Now it’s time to continue.<br/>
          Good luck!<br/>
          And I thought<br/>
          Woah! What a friend!<br/>
          So I continued the quest:<br/>
          which brought me to forecasting<br/>
          See,<br/>
          when forecasting the chance of rain<br/>
          The reporter has a database of similarly behaving cloud-patterns and compares how many of them rained at the end.<br/>
          Now, I am not a weatherman but I think I have chance at creating models.<br/>
          The question is:<br/>
          Will it be possible to run the half-Ironman without having a dangerously low blood glucose?<br/>
          And how?<br/>
          Often when I’m asked : what do I do?<br/>
          My response is that I’m learning about plasma physics.<br/>
          And more and more often people started asking me : Oh Plasma!<br/>
          So you’re studying the blood plasma, right?<br/>
          Technically..<br/>
          Hold on.<br/>
          Actually yes !<br/>
          I spend a considerable time on how we measure the changes in plasma glucose.<br/>
          Why?<br/>
          Because I believe Type One Diabetics when given the right strategy can and will control their energy to the fullest.<br/>
          And this applies to the realm of extreme sports as well.<br/>
          Now, granted I started off as guy who writes down his measurements in a notebook where<br/>
          There was room to analyze vertical change and horizontal change<br/>
          And to highlight the outliers<br/>
          It was recently that I learned about continuous glucose monitors and I started thinking.<br/>
          Now is the time.<br/>
          Continuous blood glucose monitoring paired with caloric output.<br/>
          For a greater focus on sports.<br/>
          I dedicated myself and began to study this field.<br/>
          I started by setting goals on the Apple watch,<br/>
          Distance, Time and Calorie goals<br/>
          See I thought the same amount of calories burned while walking and running will induce the same amount of change in my blood glucose.<br/>
          What I learned was that the intensity of the movement matters a lot for inducing a change in the blood.<br/>
          This was even studied in a Nature article.<br/>
          And I suspect that a function could be created to model this behavior and make the values continuous on the domain.<br/>
          Imagine that!<br/>
          A continuous intensity function which will be used as a reference for change in the glucose and will serve as a reference for the forecasting tool.<br/>
          How far ahead?<br/>
          The target is about 20 to 30 minutes during exercise.<br/>
          Relying on an equation that I am creating.<br/>
          And<br/>
          It is an equation ’m really excited about.<br/>
          If it works well it has the chance to allow me to complete the Ironman.<br/>
          If it works well it will tell me how to fuel my body during the trainings and ultimately during the big race day<br/>
          And If it works well I hope it will make a difference in someone’s life<br/>
          Alright, let me show you<br/>
          On the left side there is the predicted change in blood glucose<br/>
          For this I use mmol per liter<br/>
          On the otherside of the equation there are two main parts.<br/>
          The first part is responsible for the caloric component the other is for the glycemic component<br/>
          This is the intensity that we’ve seen in the Article<br/>
          calories per minutes per kilograms<br/>
          We have these here.<br/>
          And next to the caloric components there are the parts for the blood glucose<br/>
          mmol per liter per minutes<br/>
          that’s the original change over a small time that we will use to extrapolate<br/>
          And we’ll times this by minutes squared<br/>
          and watch this,<br/>
          a personal output operator<br/>
          to balance out the whole right side of the equation<br/>
          And to have it equal to the prediction of the blood glucose change<br/>
          The more precise the better<br/>
          Because for the mission to be successful we need the operator<br/>
<br/>
          That’s the missing piece of the puzzle<br/>
          If we define calories as work that translates to kinetic energy then we have an equation where the mass cancels out and the operator then has inverse relationship to the half of the velocity squared<br/>
          And the last crucial point is that the operator will have a direct relationship to the change in blood glucose<br/>
          Yes!<br/>
          Now, In order to learn more I’ll have to do more tests.<br/>
          And If I am successful then we’ll have a way a to do something that has never been done before.<br/>
          Correctly seeing well in advance the falls in blood glucose during exercise,<br/>
          Plus displaying real time caloric output with blood glucose forecasts.<br/>
          And for me that would help a lot in the preparations.<br/>
          Are there limitations? Yes<br/>
          For now at least<br/>
          But what it will do is measure every mode of movement as part of the triathlon and subsequently one of the hardest endurance races.<br/>
          I believe the trainings and the preparation will help me fine tune the operator<br/>
          And as the day gets closer<br/>
          It will help me<br/>
          Tame the anomalies,<br/>
          Build character,<br/>
          And hopefully<br/>
          Create surprises along the way,<br/>
          While setting a new form for medical best practice<br/>
          For those that need it.<br/>
          Thank you for listening!<br/>
        </TextWrapper>
      </Wrapper>
    );
  }
}
