export default class EventSystem {
  static events = {
    openOrderFinishing: "finish_order",
    footer_mounted: "footer_mounted",
    footer_unmounted: "footer_unmounted",
    cartCleared: "cartCleared",
    popup_product: "popup_product",
    addCombinedMenu: "addCombinedMenu",
    open_extras_selector: "open_extras_selector",
    city_unselect: "city_unselect",
    city_selected: "city_selected",
    sideMenu: "sideMenu",
    select_extras_finished: "select_extras_finished",
    msg_sdk_loaded: "msg_sdk_loaded",
    restaurant_loaded: "restaurant_loaded",
    restaurant_page_mounted: "restaurant_page_mounted",
    restaurant_page_unmounted: "restaurant_page_unmounted",
    open_restaurant_info: "open_restaurant_info",
    cartUpdated: "cartUpdated",
    urlChanged: "urlChanged",
    authentication_changed: "authentication_changed",
    googlePlacesScriptLoaded: "googlePlacesScriptLoaded",
    navbarChanged: "navbarChanged",
    hideNavBarChanged: "hideNavBarChanged",
    hideCartChanged: "hideCartChanged",
    addressPicked: "addressPicked",
    addressSelected: "addressSelected",
    citySelected: "citySelected",
    open_login_popup: "open_login_popup",
    open_address_picker_popup: "open_address_picker_popup",
    emptyAddressPicker: "emptyAddressPicker",
    contextSystemChanged: "contextSystemChanged",
    redirectIntent: "redirectIntent",
    open_profile_details_popup: "open_profile_details_popup",
    redirectToProduct: "redirectToProduct",
    openRedirectToProduct: "openRedirectToProduct",
    show_confirmation_modal: "show_confirmation_modal",
    popFloatingAddressPicker: 1,
    open_register_popup: 2,
    open_pass_forgot_popup: 3,
    selectGlobalCategory: 4,
    open_floating_address_picker_popup: "open_floating_address_picker_popup",
    hard_open_product_popup_intent: 5,
    hard_open_extra_selector_popup_intent: 6,
    order_placed: 7,
    online_payment_successful: 8,
    addressPickerClosedWithoutSetup: 9
  };

  static queue = {};
  static lastValues = {};
  static idCounter = 0;

  static publish(event, data) {
    if (typeof EventSystem.queue === "undefined") {
      return false;
    }
    if (Object.keys(EventSystem.queue).length > 0) {
      if (typeof EventSystem.queue[event] !== "undefined" && EventSystem.queue[event].length > 0)
        for (let a of EventSystem.queue[event])
          a.callback(data);
    }
    EventSystem.lastValues[event] = data;
  }

  static unsubscribe(id) {
    let eventName;
    let index = -1;
    A: for (let event in EventSystem.queue) {
      let eventSubscribers = EventSystem.queue[event];
      for (let i in eventSubscribers) {
        if (eventSubscribers[i].id === id) {
          index = i;
          eventName = event;
          break A;
        }
      }
    }
    if (index >= 0) {
      EventSystem.queue[eventName].splice(index, 1);
    }
  }

  static subscribe(event, callback) {
    if (typeof EventSystem.queue[event] === "undefined") {
      EventSystem.queue[event] = [];
    }

    let id = EventSystem.idCounter;
    EventSystem.queue[event].push({ callback, id });

    // const lastValue = EventSystem.lastValues[event];
    // if (typeof lastValue !== "undefined")
    //   callback(lastValue);

    EventSystem.idCounter++;
    return id;
  }
}
